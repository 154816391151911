import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Content from "../Content";

import cookie from './cookie.png';

import './cookieBanner.css';
import Button from "../Button";

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("cookieAccepted="))
        ?.split("=")[1];;
        if (!cookie) {
            setIsVisible(true);
        }
    }, [])
    const onClick = () => {
        document.cookie = "cookieAccepted=true;";
        setIsVisible(false);
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div className="cookie_banner">
            <Content>
                <div className="cookie_banner_content">
                    <div>
                        <h4 className="cookie_banner_title"><img src={ cookie }/><span>Cookie Notice</span> </h4>
                        <p className='cookie_banner_description'>By using this site, you agree to our use of cookies in accordance with our <Link to="/cookie-policy">Cookie Policy.</Link></p>
                    </div>
                    <div className='cookie_banner_accept_wrapper'>
                        <Button onClick={ onClick } text="Accept" className="cookie_banner_accept" />
                    </div>
                </div>
            </Content>
        </div>
    )
};

export default CookieBanner;