import Layout from "../../Common/Components/Layout/";

import "./home.css";

const Home = () => (
    <Layout>
      <div className="home_hero">
        <div className="home_hero_title_wrapper">
          <p className="home_hero_subtitle">Coming Soon to iOS</p>
          <p className="home_hero_title">Incredible Adventure</p>
        </div>
      </div>
    </Layout>
  );

export default Home;
