import Content from "../../../Common/Components/Content";
import Layout from "../../../Common/Components/Layout";

import '../policy.css';

const TermsOfUse = () => (
    <Layout>
        <div className="policy_content_wrapper">
            <Content>
                <div className="policy_content">
                    <h1>Terms of Use for Catix LLC</h1>
                    <p>Last updated: 08.07.2024</p>
                    <p>
                        Welcome to Catix LLC (GmbH). These Terms of Agreement ("Terms") govern your use of our website, 
                        games, and applications (collectively, "Services"). By accessing or using our Services, you 
                        agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.
                    </p>
                    <h2>1. Acceptance of Terms</h2>
                    <p>
                        By accessing or using our Services, you affirm that you are at least 18
                        years old or, if you are under 18, that you have obtained parental or guardian consent to use our Services.
                    </p>
                    <h2>2. Changes to Terms</h2>
                    <p>
                        We may modify these Terms from time to time. We will notify you of any changes by posting the 
                        updated Terms on our website. Your continued use of our Services after any changes indicates your acceptance of the new Terms.
                    </p>
                    <h2>3. Use of Services</h2>
                    <p>You agree to use our Services only for lawful purposes and in accordance with these Terms. You agree not to:</p>
                    <ul>
                        <li>
                            Use our Services in any way that violates any applicable law or regulation.
                        </li>
                        <li>
                            Engage in any conduct that restricts or inhibits anyone’s use or enjoyment of our Services.
                        </li>
                        <li>
                            Introduce any viruses, malware, or other harmful material to our Services.
                        </li>
                    </ul>
                    <h2>4. User Accounts</h2>
                    <p>To access certain features of our Services, you may be required to create an account. You agree to:</p>
                    <ul>
                        <li>
                            Provide accurate and complete information when creating an account.
                        </li>
                        <li>
                            Maintain the security of your account and promptly notify us of any unauthorized use.
                        </li>
                        <li>
                            Be responsible for all activities that occur under your account.
                        </li>
                    </ul>
                    <h2>5. Intellectual Property</h2>
                    <p>
                        All content and materials available on our Services, including but not limited to text, graphics, 
                        logos, and software, are the property of Catix LLC (GmbH) or its licensors and are protected by 
                        copyright, trademark, and other intellectual property laws. You agree not to:
                    </p>
                    <ul>
                        <li>
                            Reproduce, distribute, or create derivative works from our content without our express written permission.
                        </li>
                        <li>
                            Use any of our trademarks without our prior written consent.
                        </li>
                    </ul>
                    <h2>6. Privacy</h2>
                    <p>
                        Your use of our Services is also governed by our Privacy Policy, which describes how we collect, 
                        use, and protect your information. By using our Services, you consent to the practices described in our Privacy Policy.
                    </p>
                    <h2>7. Third-Party Links</h2>
                    <p>
                        Our Services may contain links to third-party websites or services that are not owned or controlled by Catix LLC (GmbH). 
                        We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any 
                        third-party websites or services. You acknowledge and agree that Catix LLC (GmbH) is not responsible or liable for 
                        any damage or loss caused by or in connection with your use of any third-party websites or services.
                    </p>
                    <h2>8. Disclaimers and Limitation of Liability</h2>
                    <p>
                        Our Services are provided "as is" and "as available" without warranties of any kind, either express or implied. 
                        Catix LLC (GmbH) does not warrant that our Services will be uninterrupted, error-free, or free from viruses 
                        or other harmful components. To the fullest extent permitted by law, Catix LLC (GmbH) disclaims all warranties, 
                        express or implied, regarding our Services, and will not be liable for any indirect, incidental, special, 
                        or consequential damages arising out of or in connection with your use of our Services.
                    </p>
                    <h2>9. Indemnification</h2>
                    <p>
                        You agree to indemnify, defend, and hold harmless Catix LLC (GmbH), its affiliates, and their 
                        respective officers, directors, employees, and agents from and against any claims, liabilities, 
                        damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' 
                        fees) arising out of or relating to your use of our Services or your violation of these Terms.
                    </p>
                    <h2>10. Governing Law and Dispute Resolution</h2>
                    <p>
                        These Terms are governed by and construed in accordance with the laws of Switzerland, 
                        without regard to its conflict of law principles. Any disputes arising out of or relating 
                        to these Terms or our Services will be resolved through binding arbitration, and judgment
                        on the arbitration award may be entered in any court having jurisdiction. You agree that any 
                        dispute resolution proceedings will be conducted only on an individual basis and not in a 
                        class, consolidated, or representative action.
                    </p>
                    <h2>11. Termination</h2>
                    <p>
                        We reserve the right to terminate or suspend your account and access to our Services,
                        without notice, if we determine that you have violated these Terms or engaged in
                        conduct that we deem inappropriate or harmful.
                    </p>

                    <h2>12. Miscellaneous</h2>
                    <ul>
                        <li>
                            <span>Entire Agreement:</span> 
                            These Terms constitute the entire agreement between you and Catix LLC (GmbH) 
                            regarding your use of our Services and supersede all prior agreements and understandings.
                        </li>
                        <li>
                            <span>Severability:</span> 
                            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
                        </li>
                        <li>
                            <span>Waiver:</span> 
                            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                        </li>
                    </ul>
                </div>
            </Content>
        </div>
    </Layout>
);

export default TermsOfUse;