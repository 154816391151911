import './content.css';

const Content = ({ children }) => {
    return (
        <div className="content_wrapper">
            { children }
        </div>
    )
}

export default Content;