import Content from '../Content';
import './hero.css';

const Hero = ({ className, title = '', subtitle='' }) => (
    <div className={ `hero ${ className ? className : "" }` }>
        <Content>
            <div className='hero_content'>
                { subtitle && (<p className='hero_subtitle'>{ subtitle }</p>) }
                { title && (<h2 className='hero_title'>{ title }</h2>) }
            </div>
        </Content>
    </div>
)

export default Hero;