import Content from "../../../Common/Components/Content";
import Layout from "../../../Common/Components/Layout";

import '../policy.css';

const CookiePolicy = () => (
    <Layout>
        <div className="policy_content_wrapper">
            <Content>
                <div className="policy_content">
                    <h1>Privacy Policy for Catix LLC</h1>
                    <p>Last Updated: 08.07.2024</p>
                    <p>
                        Welcome to Catix LLC (GmbH). We are committed to protecting your privacy and ensuring that your personal 
                        information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, 
                        and protect your information when you visit our website and use our services. By using our website and services, 
                        you accept this Privacy Policy and give your consent to the practices described herein.
                    </p>
                    <h2>1. Information We Collect</h2>
                    <p>
                        We may collect the following types of information:
                    </p>
                    <ul>
                        <li>
                            <span>Personal Information:</span> 
                            This includes information such as your name, email address, 
                            phone number, and other contact details that you provide when you contact us or sign up for our services.
                        </li>
                        <li>
                            <span>Usage Data:</span> 
                            We collect information about how you interact with our website, including your IP address, 
                            browser type, operating system, referring URLs, and our website pages you visit.
                        </li>
                        <li>
                            <span>Cookies and Tracking Technologies:</span> 
                            We use cookies and similar technologies to collect information about your browsing activities on our website.
                        </li>
                    </ul>
                    <h2>2. How We Use Your Information</h2>
                    <p>We may use the information we collect for the following purposes:</p>
                    <ul>
                        <li>
                            <span>To Provide and Improve Our Services:</span> 
                            We use your information to operate, maintain, and improve our website and services.
                        </li>
                        <li>
                            <span>To Communicate with You:</span> 
                            We may use your contact information to respond to your inquiries, send you updates about our services, and provide customer support.
                        </li>
                        <li>
                            <span>To Analyze and Improve Our Website:</span> 
                            We use usage data and cookies to understand how our website is used and to improve its functionality and user experience.
                        </li>
                        <li>
                            <span>Legal and Security Purposes:</span> 
                            We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.
                        </li>
                    </ul>
                    <h2>3. Legal Basis for Processing Personal Data (GDPR)</h2>
                    <p>
                        If you are a resident of the European Economic Area (EEA), you have the data protection rights. 
                        We process your personal data based on the following legal grounds:
                    </p>
                    <ul>
                        <li>
                            <span>Consent:</span> 
                            When you have given us explicit consent to process your personal data for a specific purpose.
                        </li>
                        <li>
                            <span>Contract:</span> 
                            When processing is necessary for the performance of a contract with you or to take steps at your request before entering into a contract.
                        </li>
                        <li>
                            <span>Legal Obligation:</span> 
                            When processing is necessary for compliance with a legal obligation to which we are subject.
                        </li>
                        <li>
                            <span>Legitimate Interests:</span> 
                            When processing is necessary for our legitimate interests, and your interests and fundamental rights do not override those interests.
                        </li>
                    </ul>
                    <h2>4. Sharing Your Information</h2>
                    <p>
                        We do not sell or rent your personal information to third parties. We may share your information in the following situations:
                    </p>
                    <ul>
                        <li>
                            <span>Service Providers:</span> 
                            We may share your information with third-party service providers who help us operate our website and provide our services.
                        </li>
                        <li>
                            <span>Legal Requirements:</span> 
                            We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                        </li>
                        <li>
                            <span>Business Transfers:</span> 
                            If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
                        </li>
                    </ul>
                    <h2>5. Your Choices</h2>
                    <p>
                        You have the following choices regarding your information:
                    </p>
                    <ul>
                        <li>
                            <span>Access and Update:</span> 
                            You can access and update your personal information by contacting us.
                        </li>
                        <li>
                            <span>Opt-Out:</span> 
                            You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in those communications.
                        </li>
                        <li>
                            <span>Cookies:</span> 
                            Most web browsers are set to accept cookies by default. You can usually modify your browser settings to remove or reject cookies.
                        </li>
                    </ul>
                    <h2>6. Security</h2>
                    <p>
                        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. 
                        However, no method of transmission over the internet or electronic storage is completely secure.
                    </p>
                    <h2>7. Children's Privacy</h2>
                    <p>
                        Our website and services, including our games, are intended for children as well as adults. 
                        We understand the importance of protecting children's privacy online and comply with the Children's 
                        Online Privacy Protection Act (COPPA).  We do not knowingly collect personal information from children 
                        under the age of 13 without verifiable parental consent. If we become aware that we have collected 
                        personal information from a child under 13 without parental consent, we will take steps to remove that 
                        information. For users under the age of 13, we may collect certain information necessary to provide our services, 
                        such as a username, password, and information about the child's activities within our games. Parents and guardians 
                        have the right to review their child's personal information, request that we delete it, and refuse to allow 
                        any further collection or use of their child's information.
                    </p>
                    <h2>8. Changes to This Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by 
                        posting the new Privacy Policy on our website. Your continued use of our website and 
                        services after any changes indicates your acceptance of the updated Privacy Policy.
                    </p>
                </div>
            </Content>
        </div>
    </Layout>
);

export default CookiePolicy;