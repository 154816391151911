import { useState } from "react";
import Button from "../../Components/Button";
import { useHeaderContext } from "../HeaderContext"

import './subscribeModal.css';

const validateEmail = (email) => String(email)
.toLowerCase()
.match(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
); 

const subscribeText = "Don't miss out on any updates about our games. Sign up with your email to receive the latest news, exclusive sneak peeks, and early access opportunities."
const subscribedText = "Thank you for subscribing! You're now on the list to receive our best updates, news, and special content. We can't wait to get started!"

const SubscribeModal = () => {
    const { isSubscribeModalOpen, setIsSubscribeModalOpen } = useHeaderContext();
    const [hasError, setHasError] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false)

    const validate = (email) => {
        const trimmed = email.trim();
        if (!validateEmail(trimmed)) {
            setHasError(true);
            return true;
        } else {
            setHasError(false);
            return false;
        }
    }
    
    const onInputChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value.length === 0){
            setHasError(false);
            return;
        }
        if (hasError) {
            validate(e.target.value)
        }
    }
    
    const onInputBlur = () => {
        validate(email);
    }

    const closeModal = () => {
        setIsSubscribeModalOpen(false);
        setHasError(false);
        setEmail("");
        setIsSubmitted(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate(email)) {
            fetch("https://submit-form.com/ICfk0Rjjc", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                },
                body: JSON.stringify({
                  email: email,
                }),
              })
                .then(function (response) {
                    setIsSubmitted(true)
                })
                .catch(function (error) {
                  console.error(error);
                });
        }
    }

    return (
        <div className={ `subscribe_modal ${ isSubscribeModalOpen ? "subscribe_modal_opened" : "" }` }>
            <div className="subscribe_modal_overlay" onClick={ closeModal }/>
            <div className="subscribe_modal_content">
                <div className="subscribe_modal_close" onClick={ closeModal }>
                    <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.13379 11.4307C0.737305 11.041 0.750977 10.3506 1.12012 9.98144L5.03027 6.07128L1.12012 2.1748C0.750977 1.79882 0.737305 1.11523 1.13379 0.718742C1.53027 0.315422 2.2207 0.329094 2.58984 0.70507L6.49316 4.60839L10.3965 0.70507C10.7793 0.322258 11.4492 0.322258 11.8457 0.718742C12.249 1.11523 12.249 1.78515 11.8594 2.1748L7.96289 6.07128L11.8594 9.9746C12.249 10.3643 12.2422 11.0273 11.8457 11.4307C11.4561 11.8271 10.7793 11.8271 10.3965 11.4443L6.49316 7.54101L2.58984 11.4443C2.2207 11.8203 1.53711 11.8271 1.13379 11.4307Z" />
                    </svg>
                </div>
                <p className="subscribe_modal_title">{ isSubmitted ? "Thanks for Joining Us! 🌟" : "Subscribe for Updates" }</p>
                <p className="subscribe_modal_description">{ isSubmitted ? subscribedText : subscribeText }</p>
                { !isSubmitted && (
                    <form onSubmit={ handleSubmit } className="subscribe_form">
                    <div className="subscribe_form_wrapper">
                        <div className="subscribe_form_input_wrapper">
                            <input
                                type="text"
                                name="email"
                                onChange={ onInputChange }
                                value={ email }
                                onBlur={ onInputBlur }
                                className={ `subscribe_form_email ${ hasError ?  "subscribe_form_email_error" : "" }` }
                                placeholder="Your email"
                            />
                            { hasError && (<p className="subscribe_modal_error">Please, enter valid email</p>)}
                        </div>
                        <Button type="submit" text="Subscribe" className="subscribe_form_submit"/>
                    </div>
                </form>
                ) }
            </div>
        </div>
    )
}

export default SubscribeModal;