import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from "../../Footer";
import Header from "../../Header";
import CookieBanner from "../CookieBanner";

import './layout.css';

const Layout = ({ children }) => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div>
            <Header />
            <div className="content_layout">
                { children }
                <Footer />
                <CookieBanner />
            </div>
        </div>
    )
}

export default Layout;