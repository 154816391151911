import Content from "../../Common/Components/Content";
import Layout from "../../Common/Components/Layout";
import Hero from "../../Common/Components/Hero";
import TeamCard from "./TeamCard";

import dog from './images/dog.png';
import cat from './images/cat.png';

import './about.css';
import Socials from "../../Common/Socials";

const team = [
    {
        name: "Alexander",
        photo: dog,
        position: "Co-Founder & Lead Developer",
        legend: "Seasoned developer with a strong background in software engineering and game development. With a passion for coding and a knack for problem-solving, he leads the technical side of Catix. Expertise ensures that our games and apps are built with the highest standards of quality and performance." 
    },
    {
        name: "Anzhela",
        photo: cat,
        position: "Co-Founder & Creative Director",
        legend: "Brings a wealth of creativity and design experience to Catix. As the visionary behind our projects, she is responsible for the artistic direction and user experience of our games and apps. Innovative ideas and attention to detail help create engaging and visually stunning products." 
    },
]

const About = () => (
    <Layout>
        <Hero className="about_hero"/>
        <div className="about_content_wrapper">
            <Content>
                <div className="about_content">
                    <h1>About</h1>
                    <h2>Our Story</h2>
                    <p>Welcome to Catix! Our small but mighty team was inspired by an unexpected source: two majestic Maine Coons named Grand Star and Yeti. We are passionate about creating engaging and innovative mobile games and apps that captivate and entertain users of all ages. Founded by two enthusiastic and dedicated developers, our small but mighty team is driven by a shared vision of bringing fun and functionality to your fingertips.</p>
                    <h2>Our Mission</h2>
                    <p>At Catix, our mission is to revolutionize the mobile gaming industry by delivering high-quality, engaging, and immersive experiences. We believe in the power of play and the potential of mobile platforms to bring joy, challenge, and community to users everywhere. Our goal is to craft products that resonate with gamers of all ages and backgrounds, fostering a sense of adventure and wonder in every interaction.</p>
                    <h2>Meet the Team</h2>
                    <p>Our team is a group of talented individuals, each bringing their unique skills and perspectives to the table. Together, we work tirelessly to turn our vision into reality.</p>
                    <div className="about_team">
                        { team.map((teamMember) => ( <TeamCard { ...teamMember } key={ teamMember.name }/> ) ) }
                    </div>
                    <h2>Contact Us</h2>
                    <p>
                        We love to hear from our community! Whether you have a question, feedback, or just want to say hello, feel free to reach out to us.
                    </p>
                    <div className="about_socials">
                        <Socials />
                    </div>
                </div>
            </Content>
        </div>
    </Layout>
);

export default About;