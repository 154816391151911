import { createContext, useEffect, useState, useContext } from 'react'

const useHeader = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);

    useEffect(() => {
        const onResize = (e) => {
            if (window.innerWidth > 767) {
                setIsMenuOpened(false);
            }
        }
        window.addEventListener('resize', onResize)
    }, []);

    return {
        isMenuOpened,
        isSubscribeModalOpen,

        setIsMenuOpened,
        setIsSubscribeModalOpen
    }
}

const HeaderContext = createContext(null);

export const useHeaderContext = () => useContext(HeaderContext);

const HeaderContextProvider = ({ children }) => (
    <HeaderContext.Provider value={ useHeader() }>
        { children }
    </HeaderContext.Provider>
)

export default HeaderContextProvider;