import { createBrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import CookiePolicy from "./Pages/Policies/CookiePolicy";
import PrivacyPolicy from "./Pages/Policies/PrivacyPolicy";
import TermsOfUse from "./Pages/Policies/TermsOfUse";
import Impressum from "./Pages/Policies/Impressum";
import Games from "./Pages/Games";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/games",
      element: <Games />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/apps",
      element: <Home />,
    },
    {
        path: "/terms-of-use",
        element: <TermsOfUse />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/cookie-policy",
        element: <CookiePolicy />,
      },
      {
        path: "/impressum",
        element: <Impressum />,
      },
  ]);

  export default router;