import instagram from './instagram.png';

import './socials.css';

const socialsList = [
    {
        name: 'twitter',
        href: "https://www.instagram.com/catix_ch/",
        src: instagram,
        alt: "instagram link"
    },
]

const Socials = () => (
    <div className="socials_wrapper">
        { socialsList.map((social) => (
            <a className="social_item" key={ social.name } href={ social.href } target='_blank' rel="noreferrer">
                <img src={ social.src } alt={ social.alt } />
            </a>
        )) }
    </div>
)

export default Socials;