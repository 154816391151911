import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./Router";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import "./index.css";

const firebaseConfig = {
  apiKey: "AIzaSyBhXVlrq2jLNgRYU8LHMu_L36uMTo6nlcE",
  authDomain: "catix-website.firebaseapp.com",
  projectId: "catix-website",
  storageBucket: "catix-website.appspot.com",
  messagingSenderId: "735709492144",
  appId: "1:735709492144:web:82d1b72a61995f9555dc75",
  measurementId: "G-8Z6T6HVYRW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
