import './teamCard.css';

const TeamCard = ({ photo, position, legend }) => (
    <div className="team_member">
        <div className="team_member_photo">
            <img src={ photo } />
        </div>
        <div>
            <h3>{ position }</h3>
            <p>{ legend }</p>
        </div>
    </div>
);

export default TeamCard;