import Content from "../../../Common/Components/Content";
import Layout from "../../../Common/Components/Layout/";

import '../policy.css';

const CookiePolicy = () => (
    <Layout>
        <div className="policy_content_wrapper">
            <Content>
                <div className="policy_content">
                    <h1>Cookie Policy for Catix LLC (GmbH)</h1>
                    <p>Last Updated: 08.07.2024</p>
                    <p>This Cookie Policy explains how Catix LLC (GmbH) 
                        ("we", "us", "our") uses cookies and similar technologies 
                        on our website and in our services. By using our website, you agree to 
                        the use of cookies as outlined in this policy.
                    </p>
                    <h2>1. What Are Cookies?</h2>
                    <p>Cookies are small text files that are placed on your device 
                        (computer, smartphone, tablet) when you visit a website. 
                        They are widely used to make websites work more efficiently, 
                        as well as to provide information to the owners of the site.
                    </p>
                    <h2>2. How We Use Cookies</h2>
                    <p>We use cookies for the following purposes:</p>
                    <ul>
                        <li>
                            <span>Essential Cookies:</span> 
                            These cookies are necessary for the website to function properly. They enable you to navigate our site and use its features.
                        </li>
                        <li>
                            <span>Performance Cookies:</span> 
                            These cookies collect information about how you use our website, such as which 
                            pages you visit most often. This information helps us improve the performance of our site.
                        </li>
                        <li>
                            <span>Functionality Cookies:</span> 
                            These cookies allow our website to remember choices you make and provide enhanced, more personalized features.
                        </li>
                        <li>
                            <span>Analytics Cookies:</span> 
                            These cookies are used to collect information about 
                            traffic to our website and how users interact with our site. 
                            We use this information to help improve our website and services.
                        </li>
                        <li>
                            <span>Advertising Cookies:</span> 
                            These cookies are used to deliver advertisements that are relevant to you and your interests.
                             They also help limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.
                        </li>
                    </ul>
                    <h2>3. Types of Cookies We Use</h2>
                    <ul>
                        <li>
                            <span>Session Cookies:</span> 
                            These are temporary cookies that expire when you close your browser.
                        </li>
                        <li>
                            <span>Persistent Cookies:</span> 
                            These cookies remain on your device for a set period or until you delete them.
                        </li>
                    </ul>
                    <h2>4. Third-Party Cookies</h2>
                    <p>
                        We may also use third-party cookies from service providers, 
                        such as analytics and advertising partners. 
                        These third parties may use cookies to collect information 
                        about your online activities over time and across different websites.
                    </p>
                    <h2>5. Managing Cookies</h2>
                    <p>
                        Most web browsers are set to accept cookies by default. 
                        However, you can choose to set your browser to remove or reject cookies. 
                        Please note that if you choose to disable cookies, some features of our website may not function properly.
                    </p>
                    <h2>6. Your Choices</h2>
                    <p>
                        By using our website, you agree to the use of cookies as described in this policy. 
                        You can withdraw your consent at any time by deleting the cookies stored on your 
                        device and adjusting your browser settings to refuse cookies.
                    </p>
                    <h2>7. Changes to This Cookie Policy</h2>
                    <p>
                        We may update this Cookie Policy from time to time. We will notify you of any 
                        changes by posting the new Cookie Policy on our website. Your continued use of our 
                        website after any changes indicates your acceptance of the updated Cookie Policy.
                    </p>
                </div>
            </Content>
        </div>
    </Layout>
);

export default CookiePolicy;