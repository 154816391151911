import Content from "../../../Common/Components/Content";
import Layout from "../../../Common/Components/Layout/";

import '../policy.css';

const CookiePolicy = () => (
    <Layout>
        <div className="policy_content_wrapper">
            <Content>
                <div className="policy_content">
                    <h1>Impressum</h1>
                    <h2>Catix LLC (GmbH)</h2>
                    <p>Am Mattenhof 2D</p>
                    <p>6010 Kriens</p>
                    <p>Switzerland</p>
                    <p>E-mail: contact@catix.ch</p>
                    <p>VAT Identification Number: CHE-­331.634.716 MWST</p>
                </div>
            </Content>
        </div>
    </Layout>
);

export default CookiePolicy;