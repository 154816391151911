import Content from '../Components/Content';
import Socials from '../Socials';
import Logo from '../Components/Logo';

import './footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer_wrapper">
            <Content>
                <Socials />
                <div className="footer_company">
                    <Logo className="footer_company_logo"/> 
                    <h2 className="footer_company_name">Catix</h2>
                </div>
                <p className="footer_company_description">Join us on this exciting journey as we create the future of mobile gaming, one app at a time.</p>
                <div className="footer_divider"/>
                <div className="footer_copyright">
                    <p>Copyright © 2024 Catix. All rights reserved.</p>
                    <Link to="/terms-of-use" className="footer_link">Terms of Use</Link>
                    <Link to="/privacy-policy" className="footer_link">Privacy Policy</Link>
                    <Link to="/cookie-policy" className="footer_link">Cookie Policy</Link>
                    <Link to="/impressum" className="footer_link">Impressum</Link>
                </div>
            </Content>
        </div>
)};

export default Footer;