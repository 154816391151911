import { NavLink } from "react-router-dom";

import Content from "../Components/Content";
import Logo from "../Components/Logo";

import './header.css';
import Button from "../Components/Button";
import HeaderContextProvider, { useHeaderContext } from "./HeaderContext";
import SubscribeModal from "./Modal";

const Header = () => {
    const { isMenuOpened, setIsMenuOpened, setIsSubscribeModalOpen } = useHeaderContext()
    const onClick = () => {
        setIsMenuOpened(!isMenuOpened);
    }

    const onSubscribeClick = () => {
        setIsSubscribeModalOpen(true);
    }
    return (
        <div className="header_wrapper">
            <Content>
                <div className="header_content_wrapper">
                    <NavLink to="/" className="header_link header_link_home">
                        <span className="header_link_home_wrapper">
                            <span>
                                <Logo />
                            </span>
                            <span className="header_link_home_text">Catix</span>
                        </span>
                    </NavLink>                   
                    <nav className={ `header_mobile_menu ${ isMenuOpened ? "header_mobile_menu_opened" : "" }` }>
                        <ul className="header_navigation">
                            <li className="header_link_wrapper">
                                <NavLink to="/games" className="header_link" onClick={ onClick }>Games</NavLink>
                            </li>
                            {/* <li className="header_link_wrapper">
                                <NavLink to="/apps" className="header_link" onClick={ onClick }>Apps</NavLink>
                            </li> */}
                            <li className="header_link_wrapper">
                                <NavLink to="/about" className="header_link" onClick={ onClick }>About</NavLink>
                            </li>
                        </ul>
                    </nav>
                    <Button text="Subscribe" className="header_button" onClick={ onSubscribeClick }/>
                    <div className={ `header_open_menu ${ isMenuOpened ? "header_open_menu_opened" : "" }` } onClick={ onClick }>
                        <svg width="19" height="12" viewBox="0 0 19 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.43555 11.5645C8.95703 11.5645 8.55664 11.3887 8.15625 10.9883L0.929688 3.5957C0.636719 3.29297 0.490234 2.94141 0.490234 2.52148C0.490234 1.65234 1.18359 0.949219 2.04297 0.949219C2.47266 0.949219 2.87305 1.125 3.19531 1.44727L9.44531 7.89258L15.6855 1.44727C16.0078 1.125 16.4082 0.949219 16.8281 0.949219C17.6875 0.949219 18.3809 1.65234 18.3809 2.52148C18.3809 2.95117 18.2344 3.30273 17.9414 3.5957L10.7148 10.9883C10.334 11.3789 9.92383 11.5645 9.43555 11.5645Z"/>
                        </svg>
                    </div>
                </div>
            </Content>
            <SubscribeModal />
        </div>
    )
}

export default () => (
    <HeaderContextProvider>
        <Header />
    </HeaderContextProvider>
)