import Content from "../../Common/Components/Content"
import Hero from "../../Common/Components/Hero";
import Layout from "../../Common/Components/Layout"

import './games.css';

const gamesList = [
    {
        className: 'game1',
        title: 'Incredible Adventure',
        subtitle: 'Coming Soon'
    },
    {
        className: 'game2',
        title: '***',
        subtitle: 'Planned in 2025'
    }
]

const Games = () => (
    <Layout>
        <div className="games_wrapper">
            <Content>
                <h2 className="games_title">Games</h2>
            </Content>
            <div>
                { gamesList.map(({ className, title, subtitle }) => (<Hero className={ className } title={ title } subtitle={ subtitle }/>)) }
            </div>
        </div>
    </Layout>
)

export default Games;